import axios from "axios";
import { getToken } from "src/constants/token";

export const getDetailOrder = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_API_URL + `/api/new_order/detail/${id}`;
  const token = getToken();

  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };
    return await axios.get(url, axiosConfig);
  } catch (error) {
    console.log(error);
  }
};
