import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import vnpay from "./assets/images/vnpay.png";
import cash from "./assets/images/cash.png";
import qr from "./assets/images/qr.png";

import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { createdUser, createOrder } from "./service";
import { useLanguage } from "src/context/LanguageProvider";
import { translations } from "src/constants/translate";
import { useSnackbar } from "src/components/CustomSnackbar";

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { showSnackbar } = useSnackbar();
  const { language } = useLanguage();

  const paymentMethods = [
    // { id: "vnpay", label: "VNPay", imgSrc: vnpay },
    {
      id: "qrcode",
      label: `${translations[language as "en" | "jp" | "vn"].c_qr}`,
      imgSrc: qr,
    },
    {
      id: "cash",
      label: `${translations[language as "en" | "jp" | "vn"].c_cash}`,
      imgSrc: cash,
    },
  ];

  const [selectedMethod, setSelectedMethod] = useState<string | null>(
    paymentMethods[0]?.id || null
  );
  const [billNumber, setBillNumber] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [couponCode, setCouponCode] = useState<string>("");
  const [showCouponField, setShowCouponField] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");

    if (status === "close") {
      showSnackbar("Bạn đã hủy giao dịch", "warning");
    }
  }, [location.search, showSnackbar]);

  useEffect(() => {
    const randomBillNumber = Math.floor(1000 + Math.random() * 9000);
    setBillNumber(randomBillNumber);
    localStorage.setItem("bill_number", randomBillNumber.toString());
  }, []);

  const handlePayment = async (): Promise<void> => {
    if (!selectedMethod) {
      showSnackbar(
        `${translations[language as "en" | "jp" | "vn"].c_select_method}`,
        "warning"
      );
      return;
    }

    const tableNumber = localStorage.getItem("tableNumber") || "";
    const tableNumberQR = localStorage.getItem("tableNumberQR") || "";
    const branch = localStorage.getItem("branch_id") || "";
    const cartIds = JSON.parse(localStorage.getItem("cart_ids") || "[]");

    if (cartIds.length === 0) {
      showSnackbar("Missing cart IDs.", "warning");
      return;
    }

    let paymentMethod = "";
    switch (selectedMethod) {
      case "vnpay":
        paymentMethod = "vn_pay";
        break;
      case "cash":
        paymentMethod = "cash";
        break;
      case "credit":
        paymentMethod = "mpos";
        break;
      case "qrcode":
        paymentMethod = "qrcode";
        break;
      default:
        return;
    }

    const productType = "food";
    const externalAppName = "donchan";
    const billNumber = Number(localStorage.getItem("bill_number"));
    const bank_code = "VNPAYQR";
    const redirect_url_failure = `${process.env.REACT_APP_URL}/payment?status=close`;
    const redirect_url_success = `${process.env.REACT_APP_URL}/confirmation`;
    const branchId = branch;

    try {
      setLoading(true);

      const tableToUse = tableNumber ? tableNumber : tableNumberQR;
      const response = await createOrder(
        tableToUse,
        paymentMethod,
        cartIds,
        bank_code,
        productType,
        externalAppName,
        billNumber,
        redirect_url_failure,
        redirect_url_success,
        branchId,
        phoneNumber,
        showCouponField ? couponCode : ""
      );

      const status = response.data.status;
      const method = response.data.payment_method;
      const time = response.data.createdAt;
      const price = response.data.price;
      const id = response.data._id;
      const bill_number = response.data.bill_number;

      if (selectedMethod === "cash") {
        showSnackbar(
          `${
            translations[language as "en" | "jp" | "vn"].notification_payment
          } ${billNumber}`,
          "success"
        );
        navigate("/confirmation", {
          state: { price, status, method, time, id, bill_number },
        });
      } else if (selectedMethod === "vnpay" && response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      } else if (selectedMethod === "qrcode") {
        showSnackbar(
          `${
            translations[language as "en" | "jp" | "vn"].notification_payment
          } ${billNumber}`,
          "success"
        );
        navigate("/confirmation", {
          state: { price, status, method, time, id, bill_number },
        });
      }
    } catch (err) {
      console.error("Error creating order:", err);
      if (
        err.response &&
        err.response.data.message === "Coupon not found or expired"
      ) {
        showSnackbar("Coupon không tồn tại hoặc đã hết hạn", "error");
      } else {
        showSnackbar("Failed to create order.", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReturn = () => {
    const diningChoice = localStorage.getItem("diningChoice");
    const tableNumber = localStorage.getItem("tableNumber");
    const tableNumberQR = localStorage.getItem("tableNumberQR");

    if (tableNumber) {
      localStorage.removeItem("tableNumber");
      navigate("/table");
    } else if (tableNumberQR) {
      navigate("/list");
    } else if (diningChoice === "take away") {
      navigate("/list");
    } else {
      navigate("/");
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhoneNumber(value);
    }
  };

  return (
    <Box
      sx={{
        display: "block",
        margin: "0 auto",
        width: "100%",
        maxWidth: "650px",
      }}
      p={2}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <IoIosArrowBack fontSize={22} onClick={handleReturn} />
        <Typography variant="body1" fontWeight={550} fontSize={19} mb={0.2}>
          {translations[language as "en" | "jp" | "vn"].c_payment}
        </Typography>
      </Stack>
      <Stack spacing={2} mt={2} direction="column">
        {paymentMethods.map((method) => (
          <Box
            key={method.id}
            sx={{
              border:
                selectedMethod === method.id
                  ? "2px solid #AE3525"
                  : "1px solid rgba(3,3,3,0.2)",
              borderRadius: 1,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setSelectedMethod(method.id)}
          >
            <img src={method.imgSrc} alt={method.label} width={100} />
            <Typography variant="body1" fontWeight={550} fontSize={22} mt={2}>
              {method.label}
            </Typography>
          </Box>
        ))}
      </Stack>

      <TextField
        fullWidth
        placeholder={`${
          translations[language as "en" | "jp" | "vn"].c_enter_phone
        }`}
        variant="outlined"
        size="small"
        sx={{
          mt: 2,
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
          },
        }}
        onChange={handlePhoneNumberChange}
        value={phoneNumber}
      />

      <Stack
        direction="row"
        mt={1}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Checkbox để hiển thị TextField */}
        <FormControlLabel
          control={
            <Checkbox
              checked={showCouponField}
              onChange={(e) => setShowCouponField(e.target.checked)}
              color="primary"
            />
          }
          label={`${
            translations[language as "en" | "jp" | "vn"].c_enter_discount
          }`}
        />

        {/* Hiển thị TextField khi checkbox được check */}
        {showCouponField && (
          <TextField
            fullWidth
            placeholder={`${
              translations[language as "en" | "jp" | "vn"].c_discount
            }`}
            variant="outlined"
            size="small"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            sx={{
              width: "150px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px 0 0 4px",
              },
            }}
          />
        )}
      </Stack>

      <Button
        fullWidth
        size="small"
        variant="contained"
        sx={{ bgcolor: "#AE3525", mt: 2, fontSize: 20 }}
        onClick={handlePayment}
      >
        {translations[language as "en" | "jp" | "vn"].c_pay}
      </Button>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Payment;
