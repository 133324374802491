import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import "./assets/css/Choose.css";
import restaurant from "./assets/images/restaurant.png";
import take from "./assets/images/take-away.png";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "src/context/LanguageProvider";
import { translations } from "src/constants/translate";
import { FaArrowLeft } from "react-icons/fa6";
import { getBranchConfig } from "../Branch/service";
import { BranchIT } from "../Branch/interface";

import banner from "./assets/images/phoso.png";
import place from "./assets/images/25.11 layout.png";

const Choose = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [open, setOpen] = useState(false);

  const handleImageClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchBranchDetails = async () => {
      const params = new URLSearchParams(window.location.search);
      const branchId = params.get("branch_id");

      if (branchId) {
        try {
          const data = await getBranchConfig("branch");
          const branch = data.config.option_content.find(
            (b: BranchIT) => b.key === branchId
          );

          if (branch) {
            localStorage.setItem("branch_id", branchId);
            localStorage.setItem("bin", branch.bin);
            localStorage.setItem("logo", branch.logo);
            localStorage.setItem("card_id", branch.card_id);
            localStorage.setItem("card_name", branch.card_name);
            localStorage.setItem("shortName", branch.shortName);
          } else {
            console.warn("Branch not found for branch_id:", branchId);
          }
        } catch (error) {
          console.error("Error fetching branches:", error);
        }
      }
    };

    fetchBranchDetails();
  }, []);

  const handleChoice = (choice: string) => {
    localStorage.setItem("diningChoice", choice);
    navigate("/list");
  };

  return (
    <Box
      p={1}
      sx={{
        display: "block",
        margin: "0 auto",
        width: "100%",
        maxWidth: "600px",
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <img src={banner} alt="banner" width={150} height={150} />
        <Typography variant="h6" fontWeight={600} color="var(--title-color)">
          Phở Số Hà Thành
        </Typography>
      </Stack>

      <Box>
        <Stack direction="row" alignItems="center" gap={1} pl={2.5} mb={2}>
          <Box
            bgcolor="#9d9d9d30"
            width={50}
            borderRadius={1}
            textAlign="center"
            color="var(--text-color)"
          >
            <Typography variant="body2" borderBottom="1px solid #9d9d9d30">
              T.6
            </Typography>
            <Typography variant="body1" fontSize={18} fontWeight={550}>
              29
            </Typography>
          </Box>
          <Box color="var(--text-color)">
            <Typography variant="body1">
              Ngày <strong>29</strong> tháng <strong>11</strong> năm{" "}
              <strong>2024</strong>
            </Typography>
            <Typography variant="body1">
              Thời gian: <strong>19:00 - 22:00</strong>{" "}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} pl={2.5} mb={2}>
          <Box
            bgcolor="#9d9d9d30"
            width={50}
            borderRadius={1}
            textAlign="center"
            color="var(--text-color)"
          >
            <Typography variant="body2" borderBottom="1px solid #9d9d9d30">
              T.7
            </Typography>
            <Typography variant="body1" fontSize={18} fontWeight={550}>
              30
            </Typography>
          </Box>
          <Box color="var(--text-color)">
            <Typography variant="body1">
              Ngày <strong>30</strong> tháng <strong>11</strong> năm{" "}
              <strong>2024</strong>
            </Typography>
            <Typography variant="body1">
              Thời gian: <strong>08:00 - 22:00</strong>{" "}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} pl={2.5}>
          <Box
            bgcolor="#9d9d9d30"
            width={50}
            borderRadius={1}
            textAlign="center"
            color="var(--text-color)"
          >
            <Typography variant="body2" borderBottom="1px solid #9d9d9d30">
              T.8
            </Typography>
            <Typography variant="body1" fontSize={18} fontWeight={550}>
              01
            </Typography>
          </Box>
          <Box color="var(--text-color)">
            <Typography variant="body1">
              Ngày <strong>01</strong> tháng <strong>12</strong> năm{" "}
              <strong>2024</strong>
            </Typography>
            <Typography variant="body1">
              Thời gian: <strong>08:00 - 22:00</strong>{" "}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        p={2.5}
        bgcolor="#d7d7d730"
        borderRadius={1}
        mt={2}
      >
        <img
          src={place}
          alt="place"
          width={120}
          height={90}
          style={{ borderRadius: "10px" }}
          onClick={handleImageClick}
        />
        <Typography variant="body2" color="var(--text-color)">
          Địa chỉ:{" "}
          <strong>
            Phố đi bộ Trần Nhân Tông (Cổng công viên Thống Nhất) - Hà Nội
          </strong>
        </Typography>
      </Stack>

      <Box p={2}>
        <Typography
          variant="body1"
          fontSize={18}
          fontWeight={550}
          color="var(--title-color)"
        >
          Thông tin sự kiện:
        </Typography>
        <Typography variant="body1" mt={1} color="var(--text-color)">
          Phở Hà Nội là món ăn tinh hoa của nền ẩm thực Việt Nam, được người Hà
          Nội sáng tạo, phát triển thành món ẩm thực hấp dẫn từ những năm đầu
          thế kỷ 20, phở là món ăn phổ biến hàng đầu của người Hà Nội, đã được
          các chuyên trang ẩm thực trên thế giới đánh giá là một trong những món
          ăn hấp dẫn nhất thế giới. Tri thức dân gian Phở Hà Nội đã được Bộ Văn
          hóa, Thể thao và Du lịch xếp hạng di sản văn hóa phi vật thể quốc gia
          (2024).
        </Typography>
      </Box>
      <Box p={2}>
        <Button
          onClick={() => handleChoice("at the restaurant")}
          variant="contained"
          sx={{
            width: "100%",
            maxWidth: "600px",
            position: "fixed",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            bgcolor: "#AE3525",
            textTransform: "unset",
            fontSize: 18,
            zIndex: 1000,
          }}
        >
          Đặt món ăn
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          }}
          onClick={handleClose}
        >
          <img
            src={place}
            alt="place"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              borderRadius: "10px",
            }}
          />
        </Box>
      </Dialog>
    </Box>
    // <Box className="choose--box">
    //   <Box>
    //     <img src={banner} alt="banner" />
    //   </Box>

    //   <Button>Tiếp theo</Button>
    //   {/* <Box
    //     sx={{
    //       display: "block",
    //       margin: "0 auto",
    //       width: "100%",
    //       maxWidth: "550px",
    //     }}
    //   >
    //     <Button
    //       onClick={() => navigate("/branch")}
    //       startIcon={<FaArrowLeft />}
    //       sx={{
    //         color: "#333",
    //         mb: 2,
    //         display: "flex",
    //         justifyContent: "flex-start",
    //         width: "auto",
    //       }}
    //     >
    //       {translations[language as "en" | "jp" | "vn"].return}
    //     </Button>
    //   </Box> */}
    //   {/* <Typography variant="body1" fontWeight={550} textAlign="center" mt={5}>
    //     {translations[language as "en" | "jp" | "vn"].dine}
    //   </Typography>
    //   <Grid
    //     container
    //     spacing={2}
    //     sx={{ mt: 4, justifyContent: "center", p: 1 }}
    //   >
    //     <Grid item xs={6} sm={4} md={3}>
    //       <Button
    //         className="custom-button"
    //         variant="outlined"
    //         fullWidth
    //         onClick={() => handleChoice("at the restaurant")}
    //       >
    //         <Box component="span" sx={{ mb: 1 }}>
    //           <img src={restaurant} alt="restaurant" width={50} />
    //         </Box>
    //         {translations[language as "en" | "jp" | "vn"].atRes}
    //       </Button>
    //     </Grid>

    //     <Grid item xs={6} sm={4} md={3}>
    //       <Button
    //         className="custom-button"
    //         variant="outlined"
    //         fullWidth
    //         onClick={() => handleChoice("take away")}
    //       >
    //         <Box component="span" sx={{ mb: 1 }}>
    //           <img src={take} alt="restaurant" width={50} />
    //         </Box>
    //         {translations[language as "en" | "jp" | "vn"].tAway}
    //       </Button>
    //     </Grid>
    //   </Grid> */}
    // </Box>
  );
};

export default Choose;
