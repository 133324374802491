// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-box {
  position: relative;
}

.qr-box::after {
  content: "";
  display: block;
  width: 100%;
  height: 46px;
  background: #fff;
  position: absolute;
  top: 0;
}

.qr-box::before {
  content: "";
  display: block;
  width: 100%;
  height: 46px;
  background: #fff;
  position: absolute;
  bottom: 0;
}

.MuiTabs-indicator {
  background-color: red !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Confirm/assets/css/Confirm.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,MAAM;AACR;;AAEA;EACE,WAAW;EACX,cAAc;EACd,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".qr-box {\n  position: relative;\n}\n\n.qr-box::after {\n  content: \"\";\n  display: block;\n  width: 100%;\n  height: 46px;\n  background: #fff;\n  position: absolute;\n  top: 0;\n}\n\n.qr-box::before {\n  content: \"\";\n  display: block;\n  width: 100%;\n  height: 46px;\n  background: #fff;\n  position: absolute;\n  bottom: 0;\n}\n\n.MuiTabs-indicator {\n  background-color: red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
