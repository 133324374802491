const shortName = localStorage.getItem("shortName");

export const jp = {
  welcome: "Welcome to our app!",
  dine: "どこでお食事をされますか？",
  atRes: "レストランで",
  tAway: "持ち帰りで",
  all: "全部",
  return: "戻る",
  description: "説明",
  size: "サイズを選んでください",
  c_size: "サイズ",
  amount: "量",
  addToCart: "カートに追加する",
  notification_addToCart: "カートに追加されました!",
  notification_payment: "支払いが正常に完了しました！請求書番号：",
  c_cart: "カート",
  payment: "支払い",
  continue: "続ける",
  topping: "トッピング",
  noDTcart: "カートに商品はありません",
  c_table: "テーブル番号を選んでください",
  clear: "クリア",
  c_payment: "支払い方法を選んでください",
  c_select_method: "お支払い方法を選択してください。",
  c_cash: "現金",
  c_qr: "QRコード",
  c_pay: "支払う",
  invoice: "下の請求書を受け取ってください。",
  invoice2: "請求書をレジに持ってきてください。",
  invoice3: "現金でのお支払いはスタッフをお呼びください。",
  c_order: "あなたの注文",
  phone: "電話番号",
  c_phone: "続ける",
  c_pending: "保留中",
  c_p_success: "支払い成功！",
  c_total: "総支払い",
  c_p_time: "支払い時間",
  c_p_method: "支払い方法",
  c_wait: "支払いは保留中です",
  c_wait_des:
    "お支払いが処理中です。5分以内に返答がない場合は心配しないでください！このページを更新するか、詳細についてはお問い合わせください。",
  c_new: "新しいアイテムを追加",
  c_des_phone: "電話番号はちょうど10桁でなければなりません",
  c_scan: "コードをスキャンして支払う",
  c_point: "電話番号を入力して自動でポイントを貯める",
  c_detail: "詳細",
  c_tab_transfer: "振込",
  c_skip: "電話番号の入力をスキップ",
  c_bank: "以下の情報に従って手動で振込してください:",
  c_name_bank: `銀行: `,
  c_owner_name_bank: "口座名義人:",
  c_owner_number_bank: "口座番号:",
  c_onwer_price_bank: "金額:",
  c_onwer_des_bank: "振込内容:",
  c_copy_numberAccount: "口座番号が成功裏にコピーされました！",
  c_copy_des: "振込内容が成功裏にコピーされました！",
  c_apply_phone_success: "ポイントが正常に獲得されました!",
  c_apply_phone_failed: "ポイントの獲得に失敗しました",
  c_enter_phone: "支払いを進めるために電話番号を入力してください",
  c_enter_discount: "割引コードを追加する",
  c_discount: "割引コード",
  c_enter_phoneNumber: "有効な電話番号を入力してください。",
  c_almost: "予想配達時間",
};
