const shortName = localStorage.getItem("shortName");

export const en = {
  welcome: "Welcome to our app!",
  dine: "Where would you like to dine?",
  atRes: "At the restaurant",
  tAway: "Take away",
  all: "All",
  return: "Return",
  description: "Description",
  size: "Choose Size",
  c_size: "Size",
  amount: "Amount",
  addToCart: "Add to cart",
  notification_addToCart: "has been added to the cart!",
  notification_payment: "Payment completed successfully! Bill Number:",
  c_cart: "Cart",
  payment: "Payment",
  continue: "Continue",
  topping: "Topping",
  noDTcart: "There are no products in the cart.",
  c_table: "Choose your table number",
  clear: "Clear",
  c_payment: "Choose payment",
  c_cash: "Cash",
  c_qr: "QR code",
  c_pay: "Pay",
  invoice: "Please receive the invoice below",
  invoice2: "Please bring your invoice to the checkout counter.",
  invoice3: "Please call the staff for cash payment.",
  c_order: "Your order",
  phone: "Phone Number",
  c_phone: "Continue",
  c_pending: "Pending",
  c_p_success: "Payment Success!",
  c_total: "Total payment",
  c_p_time: "Payment time",
  c_p_method: "Payment method",
  c_select_method: "Please select a payment method.",
  c_wait: "Payment pending",
  c_wait_des:
    "Your payment is being processed. If you don't see a response within 5 minutes, don't worry! You can refresh this page or contact us for more information.",
  c_new: "Add a new item",
  c_des_phone: "Phone number must be exactly 10 digits",
  c_scan: "Scan the code to pay",
  c_point: "Enter your phone number to automatically accumulate points",
  c_detail: "Details",
  c_tab_transfer: "Bank transfer",
  c_skip: "Skip entering the phone number",
  c_bank: "Manually transfer according to the following information:",
  c_name_bank: `Bank: `,
  c_owner_name_bank: "Account holder:",
  c_owner_number_bank: "Account number:",
  c_onwer_price_bank: "Amount:",
  c_onwer_des_bank: "Transfer description:",
  c_copy_numberAccount: "Account number copied successfully!",
  c_copy_des: "Transfer description copied successfully!",
  c_apply_phone_success: "Points successfully accumulated!",
  c_apply_phone_failed: "Points accumulation failed.",
  c_enter_phone: "Enter phone number to proceed with payment",
  c_enter_discount: "Add discount code",
  c_discount: "Discount code",
  c_enter_phoneNumber: "Please enter a valid phone number.",
  c_almost: "Estimated delivery time",
};
