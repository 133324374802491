import axios from "axios";
import { getToken } from "src/constants/token";

export const getBranchConfig = async (type: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/config/list/branch?type=${type}`;
  const token = getToken();

  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };
    const response = await axios.get(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch branch config:", error);
    throw error;
  }
};
