import axios from "axios";
import { getToken } from "src/constants/token";

export const createCart = async (
  products: Array<{
    sku: string;
    product_id: string;
    amount: number;
    topping_ids: string[];
  }>
): Promise<any> => {
  const requestData = {
    products,
  };

  const token = getToken();
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };

  const url = process.env.REACT_APP_API_URL + "/api/cart/create";

  try {
    const response = await axios.post(url, requestData, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error creating cart: ", error);
    throw new Error("Failed to create cart");
  }
};
