export const formatCurrency = (amount: number): string => {
  if (isNaN(amount)) {
    return "0 VNĐ";
  }

  return (
    amount
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ"
  );
};
