// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideIn {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideOut {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  
  .box-add {
    animation: slideIn 0.3s forwards;
  }
  
  .box-close {
    animation: slideOut 0.3s forwards;
  }`, "",{"version":3,"sources":["webpack://./src/pages/List/assets/css/List.css"],"names":[],"mappings":"AAAA;IACI;MACE,2BAA2B;MAC3B,UAAU;IACZ;IACA;MACE,wBAAwB;MACxB,UAAU;IACZ;EACF;;EAEA;IACE;MACE,wBAAwB;MACxB,UAAU;IACZ;IACA;MACE,2BAA2B;MAC3B,UAAU;IACZ;EACF;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,iCAAiC;EACnC","sourcesContent":["@keyframes slideIn {\n    0% {\n      transform: translateY(100%);\n      opacity: 0;\n    }\n    100% {\n      transform: translateY(0);\n      opacity: 1;\n    }\n  }\n  \n  @keyframes slideOut {\n    0% {\n      transform: translateY(0);\n      opacity: 1;\n    }\n    100% {\n      transform: translateY(100%);\n      opacity: 0;\n    }\n  }\n  \n  .box-add {\n    animation: slideIn 0.3s forwards;\n  }\n  \n  .box-close {\n    animation: slideOut 0.3s forwards;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
