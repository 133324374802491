import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { translations } from "src/constants/translate";
import { useLanguage } from "src/context/LanguageProvider";
import { formatCurrency } from "src/utils/formatCurrency";

import success from "./assets/images/tick-mark.png";
import pending from "./assets/images/clock.png";
import vib from "./assets/images/VIB.png";

import "./assets/css/Confirm.css";
import { getDetailOrder } from "./service";
import { en } from "src/constants/en";
import { OrderDetails, PaymentMethod } from "./interface";
import { MdContentCopy } from "react-icons/md";
import { useSnackbar } from "src/components/CustomSnackbar";

const Confirm = () => {
  const { language } = useLanguage();
  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idFromUrl = queryParams.get("id");
  const {
    id = idFromUrl || "",
    price,
    bill_number,
    method,
  }: {
    id: string;
    price: number;
    bill_number: number;
    method: string;
  } = location.state || {};

  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [currentStatus, setCurrentStatus] = useState<string | undefined>(
    undefined
  );
  const [selectedTab, setSelectedTab] = useState(0);

  const [loading, setLoading] = useState(true);
  const [timePassed, setTimePassed] = useState(0);
  const [copied, setCopied] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const accountNumber = localStorage.getItem("card_id");
  const bin = localStorage.getItem("bin");
  const card_name = localStorage.getItem("card_name");
  const logo = localStorage.getItem("logo");
  const shortName = localStorage.getItem("shortName");
  const qrImageUrl = `https://img.vietqr.io/image/${bin}-${accountNumber}-QNmILq7.png?amount=${price}&addInfo=PHOSO${bill_number}`;

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setLoading(true);
        const response = await getDetailOrder(id);
        setOrderDetails(response.data);
      } catch (err) {
        console.error("Error fetching order details:", err);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchOrderDetails();
    }
  }, [id]);

  useEffect(() => {
    const img = new Image();
    img.src = qrImageUrl;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, [qrImageUrl]);

  useEffect(() => {
    if (orderDetails) {
      setCurrentStatus(orderDetails.status);
      console.log("Current Status Updated:", orderDetails.status);
    }
  }, [orderDetails]);

  const checkStatus = async () => {
    try {
      const response = await getDetailOrder(id);
      setOrderDetails(response.data);
      setCurrentStatus(response.data.status);
    } catch (err) {
      console.error("Error fetching order status:", err);
    }
  };

  useEffect(() => {
    if (method === "cash") {
      let interval: NodeJS.Timeout;

      if (currentStatus === "pending") {
        interval = setInterval(() => {
          setTimePassed((prev) => prev + 2);
          checkStatus();

          if (timePassed >= 300) {
            clearInterval(interval);
            setLoading(false);
          }
        }, 2000);
      }

      return () => clearInterval(interval);
    }
  }, [currentStatus, id, method, timePassed]);

  useEffect(() => {
    if (method === "qrcode") {
      let interval: NodeJS.Timeout;

      if (currentStatus === "pending") {
        interval = setInterval(() => {
          setTimePassed((prev) => prev + 2);
          checkStatus();

          if (timePassed >= 300) {
            clearInterval(interval);
            setLoading(false);
          }
        }, 2000);
      }

      return () => clearInterval(interval);
    }
  }, [currentStatus, id, timePassed]);

  const formatDateTime = (isoString: string) => {
    const date = new Date(isoString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  };

  const formatTime = (isoString: string, additionalMinutes: number = 4) => {
    const date = new Date(isoString);

    date.setMinutes(date.getMinutes() + additionalMinutes);

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const handleCopyBank = () => {
    navigator.clipboard.writeText(accountNumber || "").then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      showSnackbar(
        `${translations[language as "en" | "jp" | "vn"].c_copy_numberAccount}`,
        "success"
      );
    });
  };

  const handleCopyOrder = () => {
    navigator.clipboard.writeText(orderString).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      showSnackbar(
        `${translations[language as "en" | "jp" | "vn"].c_copy_des}`,
        "success"
      );
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const renderPending = () => (
    <Box>
      <img src={pending} alt="pending" width={50} />
      <Typography variant="body1" fontSize={20} mt={1} fontWeight={500} mb={2}>
        {translations[language as "en" | "jp" | "vn"].c_pending}
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        color="text.secondary"
        fontSize={13}
        fontWeight={500}
        mb={1}
      >
        {method === "cash"
          ? translations[language as "en" | "jp" | "vn"].invoice3
          : method === "qrcode"
          ? translations[language as "en" | "jp" | "vn"].invoice2
          : ""}
      </Typography>
    </Box>
  );

  const renderTimeoutMessage = () => (
    <>
      <Typography variant="h6" color="#AE3525">
        {translations[language as "en" | "jp" | "vn"].c_wait}
      </Typography>
      <Typography variant="body2" mt={1} mb={3}>
        {translations[language as "en" | "jp" | "vn"].c_wait_des}
      </Typography>
    </>
  );

  const renderSuccess = () => (
    <Box>
      <Box
        sx={{
          display: "flex",
          margin: "0 auto",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "#bfffbf",
          width: 60,
          borderRadius: "50%",
          height: 60,
        }}
      >
        <img src={success} alt="success" width={50} />
      </Box>
      <Typography variant="body1" fontWeight={550} fontSize={20} mt={1} mb={1}>
        {translations[language as "en" | "jp" | "vn"].c_p_success}
      </Typography>
    </Box>
  );

  const paymentMethodsTranslations: Record<
    PaymentMethod,
    Record<string, string>
  > = {
    cash: {
      en: "Cash",
      vn: "Tiền mặt",
      jp: "現金",
    },
    vnpay: {
      en: "VNPay",
      vn: "VNPay",
      jp: "VNPay",
    },
    qrcode: {
      en: "QR code",
      vn: "Mã QR",
      jp: "QRコード",
    },
    transfer: {
      en: "Bank transfer",
      vn: "Chuyển khoản",
      jp: "振込",
    },
  };
  console.log("ss", paymentMethodsTranslations);
  const translatedMethod = orderDetails
    ? paymentMethodsTranslations[orderDetails.payment_method]?.[language] ||
      orderDetails.payment_method
    : "";

  const numberOrder = localStorage.getItem("bill_number");
  const orderString = `PHOSO${numberOrder}`;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "650px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        mt: 4,
      }}
    >
      <Box sx={{ width: "100%", p: 2 }}>
        {method === "cash" ? (
          <>
            {loading
              ? renderPending()
              : currentStatus === "pending"
              ? timePassed < 300
                ? renderPending()
                : renderTimeoutMessage()
              : currentStatus === "success"
              ? renderSuccess()
              : null}

            <Divider></Divider>
            <Box mt={3} mb={3}>
              <Typography variant="body2" color="text.secondary" fontSize={16}>
                {translations[language as "en" | "jp" | "vn"].c_total}
              </Typography>
              <Typography variant="body1" fontWeight={500} fontSize={25}>
                {orderDetails
                  ? formatCurrency(orderDetails.price)
                  : "Price not available"}
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    fontWeight: 550,
                    textAlign: "start",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    padding: "13px",
                    mt: 5,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="text.secondary"
                  >
                    {translations[language as "en" | "jp" | "vn"].c_order}
                  </Typography>
                  <Typography variant="body1" fontSize={25} fontWeight={600}>
                    {numberOrder}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    fontWeight: 550,
                    textAlign: "start",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    padding: "13px",
                    mt: 5,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="text.secondary"
                  >
                    {translations[language as "en" | "jp" | "vn"].phone}
                  </Typography>
                  <Typography variant="body1" fontSize={25} fontWeight={600}>
                    {orderDetails && orderDetails.phone_number}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Box
                sx={{
                  fontWeight: 550,
                  textAlign: "start",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                  padding: "13px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color="text.secondary"
                >
                  {translations[language as "en" | "jp" | "vn"].c_detail}
                </Typography>
                {orderDetails?.cart_ids &&
                  orderDetails.cart_ids.map((cartItem) => (
                    <Stack
                      key={cartItem._id}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="body1"
                        fontSize={14}
                        fontWeight={600}
                      >
                        {cartItem.product_id.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontSize={13}
                        fontWeight={600}
                      >
                        {formatCurrency(cartItem.product_id.variants[0].price)}{" "}
                      </Typography>
                    </Stack>
                  ))}
              </Box>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    fontWeight: 550,
                    textAlign: "start",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    padding: "13px",
                    mt: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="text.secondary"
                    fontSize={12}
                  >
                    {translations[language as "en" | "jp" | "vn"].c_p_time}
                  </Typography>
                  <Typography variant="body1" fontSize={15} fontWeight={600}>
                    {orderDetails
                      ? formatDateTime(orderDetails.updatedAt)
                      : "Updated time not available"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box
                  sx={{
                    fontWeight: 550,
                    textAlign: "start",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    padding: "12px",
                    mt: 1,
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="text.secondary"
                    fontSize={12}
                  >
                    {translations[language as "en" | "jp" | "vn"].c_p_method}
                  </Typography>
                  <Typography variant="body1" fontSize={16} fontWeight={600}>
                    {translatedMethod}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : method === "qrcode" ? (
          <>
            {loading
              ? renderPending()
              : currentStatus === "pending"
              ? timePassed < 300
                ? renderPending()
                : renderTimeoutMessage()
              : currentStatus === "success"
              ? renderSuccess()
              : null}

            <Divider></Divider>

            <Box sx={{ borderColor: "divider", width: "100%" }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  "& .MuiTab-root": {
                    flex: 1,
                    textAlign: "center",
                    color: "#000",
                    fontWeight: 550,
                    "&.Mui-selected": {
                      color: "#AE3525",
                    },
                  },
                }}
              >
                <Tab
                  label={`${translations[language as "en" | "jp" | "vn"].c_qr}`}
                />
                <Tab
                  label={`${
                    translations[language as "en" | "jp" | "vn"].c_tab_transfer
                  }`}
                />
              </Tabs>
            </Box>

            {selectedTab === 0 && (
              <>
                <Box mt={3} position="relative">
                  {!imageLoaded && (
                    <Box
                      width={300}
                      height={300}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      margin="0 auto"
                      bgcolor="#f0f0f0"
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  <img
                    src={qrImageUrl}
                    alt="QR Code for Payment"
                    width={300}
                    onLoad={() => setImageLoaded(true)}
                    loading="lazy"
                    style={{
                      display: imageLoaded ? "block" : "none",
                      margin: "0 auto",
                    }}
                  />
                </Box>
                <Box mt={3} mb={3}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontSize={16}
                  >
                    {translations[language as "en" | "jp" | "vn"].c_total}
                  </Typography>
                  <Typography variant="body1" fontWeight={500} fontSize={25}>
                    {orderDetails
                      ? formatCurrency(orderDetails.price)
                      : "Price not available"}
                  </Typography>
                </Box>
              </>
            )}

            {selectedTab === 1 && (
              <Box mt={3}>
                <Typography variant="body1">
                  {translations[language as "en" | "jp" | "vn"].c_bank}
                </Typography>
                <Box
                  sx={{
                    fontWeight: 550,
                    textAlign: "start",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    padding: "13px",
                    mt: 2,
                  }}
                >
                  <img
                    src={logo || ""}
                    alt="logo vib"
                    width={200}
                    loading="lazy"
                    style={{ display: "block", margin: "0 auto" }}
                  />
                  <Typography
                    variant="body2"
                    fontWeight={550}
                    textAlign="center"
                    mt={1.5}
                  >
                    {translations[language as "en" | "jp" | "vn"].c_name_bank}{" "}
                    {shortName}
                  </Typography>
                  <Box sx={{ width: "100%", mt: 3 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body2" fontWeight={400}>
                        {
                          translations[language as "en" | "jp" | "vn"]
                            .c_owner_name_bank
                        }
                      </Typography>
                      <Typography variant="body1" fontWeight={550}>
                        {card_name}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={1.5}
                    >
                      <Typography variant="body2" fontWeight={400}>
                        {
                          translations[language as "en" | "jp" | "vn"]
                            .c_owner_number_bank
                        }
                      </Typography>
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography variant="body1" fontWeight={550}>
                          {accountNumber}
                        </Typography>
                        <MdContentCopy
                          onClick={handleCopyBank}
                          fontSize={20}
                          color="#AE3525"
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={1.5}
                    >
                      <Typography variant="body2" fontWeight={400}>
                        {
                          translations[language as "en" | "jp" | "vn"]
                            .c_onwer_price_bank
                        }
                      </Typography>
                      <Typography variant="body1" fontWeight={550}>
                        {orderDetails
                          ? formatCurrency(orderDetails.price)
                          : "Price not available"}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={1.5}
                    >
                      <Typography variant="body2" fontWeight={400}>
                        {
                          translations[language as "en" | "jp" | "vn"]
                            .c_onwer_des_bank
                        }
                      </Typography>

                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography variant="body1" fontWeight={550}>
                          PHOSO{numberOrder}
                        </Typography>
                        <MdContentCopy
                          onClick={handleCopyOrder}
                          fontSize={20}
                          color="#AE3525"
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            )}

            <Grid container spacing={1}>
              <Grid item xs={orderDetails?.phone_number ? 6 : 12}>
                <Box
                  sx={{
                    fontWeight: 550,
                    textAlign: "start",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    padding: "13px",
                    mt: 5,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="text.secondary"
                  >
                    {translations[language as "en" | "jp" | "vn"].c_order}
                  </Typography>
                  <Typography variant="body1" fontSize={25} fontWeight={600}>
                    {numberOrder}
                  </Typography>
                </Box>
              </Grid>

              {orderDetails?.phone_number && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      fontWeight: 550,
                      textAlign: "start",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                      padding: "13px",
                      mt: 5,
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="text.secondary"
                    >
                      {translations[language as "en" | "jp" | "vn"].phone}
                    </Typography>
                    <Typography variant="body1" fontSize={25} fontWeight={600}>
                      {orderDetails.phone_number}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>

            <Box>
              {orderDetails?.cart_ids &&
                orderDetails.cart_ids.map((cartItem) => (
                  <Box
                    key={cartItem._id}
                    sx={{
                      fontWeight: 550,
                      textAlign: "start",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                      padding: "13px",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="text.secondary"
                    >
                      {translations[language as "en" | "jp" | "vn"].c_detail}
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="body1"
                        fontSize={14}
                        fontWeight={600}
                      >
                        {cartItem.product_id.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontSize={13}
                        fontWeight={600}
                      >
                        {formatCurrency(cartItem.product_id.variants[0].price)}{" "}
                      </Typography>
                    </Stack>
                  </Box>
                ))}
            </Box>

            <Box
              sx={{
                fontWeight: 550,
                textAlign: "start",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                padding: "13px",
                mt: 1,
              }}
            >
              <Typography
                variant="body2"
                fontWeight={500}
                color="text.secondary"
                fontSize={12}
              >
                {translations[language as "en" | "jp" | "vn"].c_almost}
              </Typography>
              <Typography variant="body1" fontSize={25} fontWeight={600}>
                {orderDetails
                  ? formatTime(orderDetails.updatedAt)
                  : "Updated time not available"}
              </Typography>
            </Box>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    fontWeight: 550,
                    textAlign: "start",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    padding: "13px",
                    mt: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="text.secondary"
                    fontSize={12}
                  >
                    {translations[language as "en" | "jp" | "vn"].c_p_time}
                  </Typography>
                  <Typography variant="body1" fontSize={15} fontWeight={600}>
                    {orderDetails
                      ? formatDateTime(orderDetails.updatedAt)
                      : "Updated time not available"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box
                  sx={{
                    fontWeight: 550,
                    textAlign: "start",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    padding: "12px",
                    mt: 1,
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="text.secondary"
                    fontSize={12}
                  >
                    {translations[language as "en" | "jp" | "vn"].c_p_method}
                  </Typography>
                  <Typography variant="body1" fontSize={16} fontWeight={600}>
                    {translatedMethod}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography variant="body1" fontWeight={500} fontSize={25}>
            Unknown payment method
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Confirm;
