import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BranchIT } from "./interface";
import { getBranchConfig } from "./service";
import { BranchCard } from "./components/BranchCard";
import { useNavigate } from "react-router-dom";

const Branch = () => {
  const navigate = useNavigate();

  const [branches, setBranches] = useState<BranchIT[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const data = await getBranchConfig("branch");
        setBranches(data.config.option_content);
        setLoading(false);

        const params = new URLSearchParams(window.location.search);
        const branchId = params.get("branch_id");

        if (branchId) {
          const branch = data.config.option_content.find(
            (b: BranchIT) => b.key === branchId
          );

          if (branch) {
            localStorage.setItem("branch_id", branchId);
            localStorage.setItem("bin", branch.bin);
            localStorage.setItem("logo", branch.logo);
            localStorage.setItem("card_id", branch.card_id);
            localStorage.setItem("card_name", branch.card_name);
            localStorage.setItem("shortName", branch.shortName);
          }
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
        setLoading(false);
      }
    };

    fetchBranches();
  }, []);

  const handleBranchClick = (
    branchId: string,
    bin: string,
    logo: string,
    card_id: string,
    card_name: string,
    shortName: string
  ) => {
    localStorage.setItem("branch_id", branchId);
    localStorage.setItem("bin", bin);
    localStorage.setItem("logo", logo);
    localStorage.setItem("card_id", card_id);
    localStorage.setItem("card_name", card_name);
    localStorage.setItem("shortName", shortName);
    navigate("/choose");
  };

  return (
    <Box
      sx={{
        display: "block",
        margin: "0 auto",
        width: "100%",
        maxWidth: "600px",
      }}
      p={1}
    >
      <BranchCard branches={branches} handleBranchClick={handleBranchClick} />
    </Box>
  );
};

export default Branch;
