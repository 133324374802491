import {
  BrowserRouter as BrowserRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import CustomAppBar from "./components/Appbar/Appbar";
import List from "./pages/List";
import { LanguageProvider } from "./context/LanguageProvider";
import ProductDetail from "./pages/List/components/ProductDetail";
import Choose from "./pages/Choose";
import Table from "./pages/Table";
import Payment from "./pages/Payment";
import Confirm from "./pages/Confirm";
import { SnackbarProvider } from "./components/CustomSnackbar";
import PutCode from "./pages/Code";
import { useEffect, useState } from "react";
import ProtectedRoute from "./components/ProtectRoute";
import { login } from "./service";
import Branch from "./pages/Branch";

function App() {
  const isLargeScreen = useMediaQuery("(min-width:500px)");
  const [isDataReady, setIsDataReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loginAndRedirect = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        localStorage.setItem("diningChoice", "at the restaurant");
        if (window.location.pathname !== "/list") {
          window.location.href = "/list";
        } else {
          setIsDataReady(true);
        }
      } else {
        try {
          const user_email = "khach@gmail.com";
          const user_password = "User123@";
          const response = await login(user_email, user_password);
          const newToken = response.headers["x-authorization"];

          localStorage.setItem("token", newToken);
          localStorage.setItem("diningChoice", "at the restaurant");

          if (window.location.pathname !== "/list") {
            window.location.href = "/list";
          } else {
            setIsDataReady(true);
          }
        } catch (error) {
          console.error("Login error:", error);
          setIsDataReady(true);
        }
      }
    };

    loginAndRedirect();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const table = params.get("table");
    const branch_id = params.get("branch_id");

    if (table) {
      localStorage.setItem("tableNumberQR", table);
    }

    if (branch_id) {
      localStorage.setItem("branch_id", branch_id);
    }

    if (table && !params.has("branch_id")) {
      params.set("table", table);
      navigate(`${window.location.pathname}?${params.toString()}`);
    }
  }, [navigate]);

  useEffect(() => {
    const keysToRemove = ["cart", "cart_ids"];
    keysToRemove.forEach((key) => {
      if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
      }
    });
  }, []);

  if (!isDataReady) {
    return <div></div>;
  }

  const theme = createTheme({
    typography: {
      fontFamily: '"Noto Sans JP", sans-serif',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <LanguageProvider>
          <div className="App">
            <CustomAppBar />

            <Routes>
              <Route
                path="/branch"
                element={
                  <ProtectedRoute>
                    <Branch />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/list"
                element={
                  <ProtectedRoute>
                    <List />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/choose"
                element={
                  <ProtectedRoute>
                    <Choose />
                  </ProtectedRoute>
                }
              />
              {!localStorage.getItem("tableNumber") && (
                <Route
                  path="/table"
                  element={
                    <ProtectedRoute>
                      <Table />
                    </ProtectedRoute>
                  }
                />
              )}
              <Route
                path="/payment"
                element={
                  <ProtectedRoute>
                    <Payment />
                  </ProtectedRoute>
                }
              />
              <Route path="/confirmation" element={<Confirm />} />
            </Routes>
          </div>
        </LanguageProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default function Main() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
