import React from "react";
import { IconButton, Typography, Stack } from "@mui/material";

interface IconLabelButtonProps {
  imgSrc: string;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const IconLabelButton: React.FC<IconLabelButtonProps> = ({
  imgSrc,
  label,
  isActive,
  onClick,
}) => {
  return (
    <Stack alignItems="center">
      <IconButton
        onClick={onClick}
        sx={{
          border: isActive ? "2px solid #FF1919" : "unset",
          width: "60px",
          height: "60px",
          transition: "0.2s ease",
          borderRadius: "4px",
        }}
      >
        <img src={imgSrc} alt="" width={30} />
      </IconButton>
      <Typography variant="body2" mt={1} fontWeight={600}>
        {label}
      </Typography>
    </Stack>
  );
};

export default IconLabelButton;
