import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BranchIT } from "../interface";

export const BranchCard = ({
  branches,
  handleBranchClick,
}: {
  branches: BranchIT[];
  handleBranchClick: any;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      display="grid"
      gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"}
      gap={2}
    >
      {branches.map((branch) => (
        <Box
          key={branch.key}
          position="relative"
          width="100%"
          height={280}
          sx={{
            cursor: "pointer",
            "&:hover .overlay": {
              bgcolor: "rgba(0, 0, 0, 0.7)",
              transition: "background-color 0.3s ease",
            },
          }}
          onClick={() =>
            handleBranchClick(
              branch.key,
              branch.bin,
              branch.logo,
              branch.card_id,
              branch.card_name,
              branch.shortName
            )
          }
        >
          <img
            src={branch.image}
            alt={branch.title}
            width="100%"
            height="100%"
            style={{
              objectFit: "cover",
              borderRadius: "10px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            }}
          />
          <Box
            className="overlay"
            sx={{
              position: "absolute",
              top: "210px",
              bgcolor: "rgba(0, 0, 0, 0.5)",
              width: "100%",
              height: 70,
              p: 1,
              borderRadius: "0px 0px 10px 10px",
              transition: "background-color 0.3s ease",
            }}
          >
            <Typography variant="body2" color="#fff">
              Chi nhánh
            </Typography>
            <Typography variant="body1" color="#fff" fontWeight={600}>
              {branch.title}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
