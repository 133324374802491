// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choose--box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Space between image and button */
  text-align: center;
  margin: 0 auto;
  padding: 16px;
  height: 100vh; /* Make the box fill the full viewport height */
  box-sizing: border-box; /* Ensures padding doesn't affect height calculation */
}

.choose--box img {
  width: 100%;
  max-width: 600px; /* Limit image size */
  height: auto; /* Maintain aspect ratio */
  flex-shrink: 0; /* Prevent shrinking */
}

.choose--box button {
  margin-top: 16px;
  width: 100%;
  max-width: 200px; /* Optional: limit button width */
}

@media (max-width: 768px) {
  .choose--box {
    padding: 8px; /* Reduce padding on smaller screens */
  }

  .choose--box img {
    max-width: 90%; /* Shrink the image further on mobile */
  }
}

.custom-button {
  border: none !important;
  outline: none !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 200px;
  color: #333 !important;
  text-transform: none !important;
  font-weight: 550 !important;
  font-size: 15px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Choose/assets/css/Choose.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B,EAAE,mCAAmC;EACnE,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,aAAa,EAAE,+CAA+C;EAC9D,sBAAsB,EAAE,sDAAsD;AAChF;;AAEA;EACE,WAAW;EACX,gBAAgB,EAAE,qBAAqB;EACvC,YAAY,EAAE,0BAA0B;EACxC,cAAc,EAAE,sBAAsB;AACxC;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB,EAAE,iCAAiC;AACrD;;AAEA;EACE;IACE,YAAY,EAAE,sCAAsC;EACtD;;EAEA;IACE,cAAc,EAAE,uCAAuC;EACzD;AACF;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,iDAAiD;EACjD,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,2BAA2B;EAC3B,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".choose--box {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between; /* Space between image and button */\n  text-align: center;\n  margin: 0 auto;\n  padding: 16px;\n  height: 100vh; /* Make the box fill the full viewport height */\n  box-sizing: border-box; /* Ensures padding doesn't affect height calculation */\n}\n\n.choose--box img {\n  width: 100%;\n  max-width: 600px; /* Limit image size */\n  height: auto; /* Maintain aspect ratio */\n  flex-shrink: 0; /* Prevent shrinking */\n}\n\n.choose--box button {\n  margin-top: 16px;\n  width: 100%;\n  max-width: 200px; /* Optional: limit button width */\n}\n\n@media (max-width: 768px) {\n  .choose--box {\n    padding: 8px; /* Reduce padding on smaller screens */\n  }\n\n  .choose--box img {\n    max-width: 90%; /* Shrink the image further on mobile */\n  }\n}\n\n.custom-button {\n  border: none !important;\n  outline: none !important;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n  height: 200px;\n  color: #333 !important;\n  text-transform: none !important;\n  font-weight: 550 !important;\n  font-size: 15px !important;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
