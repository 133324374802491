import axios from "axios";
import { getToken } from "src/constants/token";

export const getConfigCategory = async (branchKey?: string): Promise<any> => {
  const endpoint = branchKey
    ? `/api/config/list/category_${branchKey}`
    : `/api/config/list/category`;
  const url = process.env.REACT_APP_API_URL + endpoint;

  try {
    return await axios.get(url);
  } catch (error) {
    throw new Error("Failed to fetch category data");
  }
};

export const getListToppings = async (
  id: string,
  branchId: string
): Promise<any> => {
  const url =
    process.env.REACT_APP_API_URL +
    `/api/product/list-topping?product_id=${id}&branch=${branchId}`;

  try {
    return await axios.get(url);
  } catch (error) {
    console.log("Failed to fetch toppings:", error);
    throw error;
  }
};
export const getFoodDetail = async (category?: string): Promise<any> => {
  const branchId = localStorage.getItem("branch_id");
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/product/list?order_by=ASC`;

  const url = `${baseUrl}${branchId ? `&branch=${branchId}` : ""}${category && category !== "home" ? `&category[0]=${category}` : ""
    }`;

  try {
    return await axios.get(url);
  } catch (error) {
    throw new Error("Failed to fetch product data");
  }
};

export const createCart = async (
  products: Array<{
    sku: string;
    product_id: string;
    amount: number;
    topping_ids: string[];
  }>
): Promise<any> => {
  const requestData = {
    products,
  };
  const token = getToken();
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };

  const url = process.env.REACT_APP_API_URL + "/api/cart/create";

  try {
    const response = await axios.post(url, requestData, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error creating cart: ", error);
    throw new Error("Failed to create cart");
  }
};
