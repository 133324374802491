import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "src/constants/token";

export const createOrder = async (
  table: string,
  payment_method: string,
  cart_ids: string[],
  bank_code: string,
  prdoduct_type: string,
  external_app_name: string,
  bill_number: number,
  redirect_url_failure: string,
  redirect_url_success: string,
  branch: string,
  phone_number: string,
  coupon_code: string
): Promise<any> => {
  const requestData = {
    table,
    payment_method,
    cart_ids,
    bank_code,
    prdoduct_type,
    external_app_name,
    bill_number,
    redirect_url_failure,
    redirect_url_success,
    branch,
    phone_number,
    coupon_code,
  };
  const token = getToken();

  const axiosConfig: AxiosRequestConfig = {
    headers: {
      "X-Authorization": token,
    },
  };
  const url = process.env.REACT_APP_API_URL + "/api/new_order/create";
  return axios.post(url, requestData, axiosConfig);
};

export const createdUser = async (phone_number: string): Promise<any> => {
  const requestData = {
    phone_number,
  };

  const url = process.env.REACT_APP_API_URL + "/api/user/sync-user";
  return axios.post(url, requestData);
};

export const getListCoupon = async (): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/coupon/list`;
  try {
    return await axios.get(url);
  } catch (error) {
    console.error("Failed to fetch Coupon:", error);
    throw error;
  }
};
