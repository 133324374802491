const shortName = localStorage.getItem("shortName");

export const vn = {
  welcome: "Welcome to our app!",
  dine: "Bạn muốn dùng bữa ở đâu?",
  atRes: "Tại nhà hàng",
  tAway: "Mang về",
  all: "Tất cả",
  return: "Quay lại",
  description: "Mô tả",
  size: "Chọn size",
  c_size: "Kích cỡ",
  amount: "Số lượng",
  addToCart: "Thêm vào giỏ hàng",
  notification_addToCart: "đã được thêm vào giỏ hàng!",
  notification_payment: "Thanh toán thành công! Số hóa đơn:",
  c_cart: "Giỏ hàng",
  payment: "Thanh toán",
  continue: "Tiếp tục",
  topping: "Topping",
  noDTcart: "There are no products in the cart",
  c_table: "Chọn số bàn của bạn",
  clear: "Xóa",
  c_payment: "Chọn phương thức thanh toán",
  c_select_method: "Vui lòng chọn một phương thức thanh toán.",
  c_cash: "Tiền mặt",
  c_qr: "Mã QR",
  c_pay: "Thanh toán",
  invoice: "Vui lòng nhận hóa đơn phía dưới",
  invoice2: "Vui lòng cầm hóa đơn của bạn đến quầy thanh toán.",
  invoice3: "Vui lòng gọi nhân viên để thanh toán tiền mặt.",
  c_order: "Đơn hàng của bạn",
  phone: "Số điện thoại",
  c_phone: "Tiếp tục",
  c_pending: "Đang chờ xử lý",
  c_p_success: "Thanh toán thành công!",
  c_total: "Tổng thanh toán",
  c_p_time: "Thời gian thanh toán",
  c_p_method: "Phương thức thanh toán",
  c_wait: "Thanh toán đang chờ xử lý",
  c_wait_des:
    "Thanh toán của bạn đang được xử lý. Nếu bạn không thấy phản hồi trong vòng 5 phút, đừng lo lắng! Bạn có thể làm mới trang này hoặc liên hệ với chúng tôi để biết thêm thông tin.",
  c_new: "Thêm món mới",
  c_des_phone: "Số điện thoại phải đúng 10 chữ số",
  c_scan: "Quét mã để thanh toán",
  c_point: "Nhập số điện thoại để tích Point tự động",
  c_detail: "Chi tiết",
  c_tab_transfer: "Chuyển khoản",
  c_skip: "Bỏ nhập số điện thoại",
  c_bank: "Chuyển khoản thủ công theo thông tin:",
  c_name_bank: `Ngân hàng: `,
  c_owner_name_bank: "Chủ tài khoản:",
  c_owner_number_bank: "Số tài khoản:",
  c_onwer_price_bank: "Số tiền:",
  c_onwer_des_bank: "Nội dung CK:",
  c_copy_numberAccount: "Copy số tài khoản thành công!",
  c_copy_des: "Copy nội dung chuyển khoản thành công!",
  c_apply_phone_success: "Tích điểm thành công!",
  c_apply_phone_failed: "Tích điểm thất bại!",
  c_enter_phone: "Nhập số điện thoại để thanh toán",
  c_enter_discount: "Thêm mã giảm giá",
  c_discount: "Mã giảm giá",
  c_enter_phoneNumber: "Vui lòng nhập một số điện thoại hợp lệ.",
  c_almost: "Thời gian giao hàng dự kiến",
};
